<template>
    <div class="files page list-page">
        <div class="page--list" v-loading="loading">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-table :data="tableData" @selection-change="handleSelectionChange">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column label="操作位置" prop="typename" min-width="100px"></el-table-column>
                    <el-table-column label="操作人" prop="createpeople" min-width="100px"></el-table-column>
                    <el-table-column label="导出参数" prop="content">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleQueryBtn(scope.$index)">查看</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="加入任务时间" prop="starttime">
                         <template slot-scope="scope">
                            <div v-html="scope.row.starttime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="结束任务时间" prop="endtime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.endtime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务状态" prop="statusName"></el-table-column>
                    <el-table-column label="下载次数" prop="downloadnum"></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.path"
                                type="text"
                                @click.stop="handleDownload(scope.row)"
                            >下载</el-button>
                            <span v-else>无下载链接</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- list-foot-bar 默认插入元素（分页组件）会被插入右侧，
                    slot="left" 会插入左侧（预想为列表选中状态） -->
            <list-foot-bar :checked-num="checkedlist.length">
                <template slot="left">
                    <el-button v-if="auth.delete" :disabled="bulkFreezing" @click="handleBulkDelete">批量删除</el-button>
                </template>

                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="SP.pageNum"
                    :page-size="SP.pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>

            </list-foot-bar>
        </div>
        
        <!-- 查看导出参数 -->
        <el-dialog
            :visible="!!popData"
            @close="handleDialogClose"
            title="查看导出参数"
            width="800px">

            <ul v-if="popData" class="params-list">
                <li v-for="(par, key) in JSON.parse(popData.content)" :key="key">
                    {{ key }}: {{ par }}
                </li>

            </ul>

            <div slot="footer">
                <el-button @click="handleDialogClose" type="primary">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import forList from "@/mixin/forList";
export default {
	name: "Files",
    mixins: [forList],

	data() {
		return {
            popData: null,      // 点击了查看详情的数据
            checkedlist: [],    // 选中的数据
			operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            link: document.createElement("a"), // 用于激活跳转下载页面的链接
		};
	},

	computed: {

        bulkFreezing () {
            return this.operating || !this.checkedlist.length
        },
	},

	methods: {

        // 获取列表数据
        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/download/findByPage",
                params: {...this.extractParams()}
            })
            .then(res => {
                if (res.code === 2000 && res.data.list) {
                    this.total = res.data.total;
                    this.tableData.splice(0, this.tableData.length, ...res.data.list.map(item => {
                        // let downLoadURL = 'https://ausshop.jindingaus.com/down/'
                        if(this.$downLoadURL){
                            if (item.path) item.path = this.$downLoadURL + item.path;
                        }else{
                            if (item.path) item.path = this.$baseURL + item.path;
                        }
                        // 
                        return item;
                    }));

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击查看参数
        handleQueryBtn (index) {
            this.popData = this.tableData[index];
        },
        handleDialogClose () {
            this.popData = null
        },

        // el-table 选中事件，更新 data
        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        // 批量删除
        async handleBulkDelete () {
            const sure = await this.$confirm("确定要删除选中的导出文件吗");
            if (!sure) return;

            const ids = this.checkedlist.map(item => item.id).join()

            const res = await this.$oxios({
                url: "api/download/delRemind",
                method: "post",
                data: { ids }
            });

            const success = 2000 == res.code;
            this.$message({
                message: success ? "删除成功" : res.msg || "删除失败",
                type: success ? "success" : "warn"
            });
            success && this.fetchData()
        },

        // 点击下载按钮
        handleDownload (data) {
            if (!data.path) return;

            this.$oxios({
                url: "api/download/updateNum",
                method: "post",
                data: { id: data.id }
            })
            .then(res => {
                if (res.code === 2000) {
                    data.downloadnum++
                }
            })
            .catch(reason => {
                console.warn(reason);
            })
            window.open(data.path, "download");
            // export/exportorder/商品订单20220902173214.xlsx
            // this.link.href = data.path;
            // this.link.download = data.path.slice(data.path.lastIndexOf("/") + 1);
            // this.link.click();
        }

	},

	created () {
		// this.auth.save = this.$hasAuthFor("api/download/updateNum");
		this.auth.delete = this.$hasAuthFor("api/download/delRemind");
	},

};
</script>

<style lang='scss' scoped>
    .params-list {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 5px 10px;
            background-color: whitesmoke;
            border: 1px solid rgba($color: #000000, $alpha: .1);
        }
    }
</style>