import forList from "@/mixin/forList";
import ThumbnailList from "@/components/ThumbnailList.vue";
const stateEnum = [
    {name: "发送中", value: 0},
    {name: "成功",   value: 1},
    {name: "失败",   value: 2}
];

export default {
    name: 'WXMessage',
    mixins: [forList],
    components: {ThumbnailList},

    data () {
        return {
            stateEnum,
            loading: false,
            SP_: {
                searchCustCode : "",
                searchState    : ""
            },
        }
    },

    methods: {

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数

            try {
                const res = await this.$axios({
                    url: "api/message/queryMessageWeixinList",
                    params
                });
    
                if (res.code === 2000) {
                    const list = res.data.messageWeixinList;
                    this.tableData = list;
                    this.total = res.data.count;

                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取列表失败",
                        type: "warning"
                    })
                }

                this.loading = false;
            } catch (reason) {
                console.warn(reason);
                this.loading = false;
            }

        },


        // 批量操作：删除、上架、下架
        async handleBulkButton (where = "hide") {
            // if (where === "delete" && this.checkedlist.some(item => {
            //     if (item.isSale == 1) {
            //         _item = item;
            //         return true
            //     }
            //     return false
            // })) {
            //     return this.$alert(`包含已上架商品，无法批量删除`, "提示");
            // }
            const operationName = where == "delete" ? "删除" : "隐藏";
            try {
                await this.$confirm(`确定要${operationName}选中的消息吗`);
                this.loading = true;
                let res;
                if (where == "delete") res = await this.bulkDelete();
                else res = await this.bulkHide();
                
                if (res.code !== 2000 && res.code !== 1003) throw res;
                this.loading = false;
                this.$message({
                    message: `${operationName}成功`,
                    type: "success"
                })
                this.fetchData();

            } catch (reason) {
                this.loading = false;
                this.$message({
                    message: reason.msn || `${operationName}失败`,
                    type: "error"
                });
                console.warn("操作失败", reason);
            }
        },

        bulkDelete () {
            const ids = this.checkedlist.map(({id})=>id).join();
            return this.$axios({
                method: "post",
                url: "api/message/deleteMessageWeixin",
                data: {ids}
            })
        },
        // bulkHide () {
        //     const ids = this.checkedlist.map(({id})=>id).join();
        //     return this.$axios({
        //         method: "post",
        //         url: "api/evaluate/hideOrderEvaluate",
        //         data: {ids}
        //     })
        // },
    },

    created() {
        this.auth.delete  = this.$hasAuthFor("api/message/deleteMessageWeixin");
    }
}