<template>
	<div class="messages module-wrapper">
		<!-- <keep-alive> -->
			<router-view></router-view>
		<!-- </keep-alive> -->
	</div>
</template>

<script>
export default {
	name: "Messages",

	data() {
		return {};
	},
	computed: {},
	methods: {},
	// created () {},
	// beforeMount () {},
	// mounted () {},
	// components: {},
	// watch: {}
};
</script>

<style lang="scss" scoped></style>
